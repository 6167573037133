@import "~bootstrap/scss/bootstrap";

form ul {
    list-style-type:none;
    padding: 0;
    margin: 0;
}

form .btn-amount {
    padding: 0px;
    height: 100%;
    width: 100%;
}

.select-user {
    width: 100%;
}

.btn-pin {
    width: 100%;
}


// hide input number arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// product list
.card-header .nav-link {
    color: black;
    border: solid 1px var(--bs-secondary);
}
.card-header .nav-link.active {
    color: black;
    background: var(--bs-yellow) !important;
    border: solid 1px var(--bs-dark);
}

.product-grid .card-body {
    padding: .5rem .5rem;
}
.product-grid .card-header,
.product-grid .card-footer {
    padding: .2rem .2rem;
}
